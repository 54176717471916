@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}

.App {
  text-align: center;
}
 #modal-preferred {
   height: 100%;
 }
 